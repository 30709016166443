.info {
   width: 100%;
   height: 100%;
   background-color: var(--main-white-color);
}
.info-grid {
   height: 100%;
   display: grid;
   grid-template-columns: repeat(12, 1fr);
   max-width: 1160px;
   margin: 0 auto;
   border-bottom: solid 1px grey;
}
.info-header {
   padding-top: 3em;
   grid-column: 1/14;
   justify-self: center;
   color: var(--main-black-color);
}

.info-segment {
   grid-column: 2/12;
   display: grid;
   grid-template-columns: repeat(4, 1fr);
   justify-items: center;
   text-align: center;
}
.segment h2 {
   color: var(--main-accent-color);
}
.segment h3 {
   color: var(--main-black-color);
   font-weight: 400;
   padding: 0.5em 0;
}
.segment {
   padding: 3.5em 0;
}
@media only screen and (max-width: 1250px) {
   .info-grid {
      max-width: 1050px;
   }
}
@media only screen and (max-width: 1101px) {
   .info-grid {
      max-width: 920px;
   }
}

@media only screen and (max-width: 830px) {
   .info-grid {
      max-width: 760px;

      text-align: center;
   }
   .info-segment {
      grid-gap: 2em;
   }
}

@media only screen and (max-width: 600px) {
   .info-header {
      font-size: 1.4em;
   }
   .info-grid {
      max-width: 380px;
      padding: 0 2em;
      border: none;
   }
   .info-segment {
      grid-column: 1/13;
      grid-gap: 0;
      margin-top: 1em;
   }
   .segment {
      grid-column: 1/6;
      padding: 2em 0;
   }
}
