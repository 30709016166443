.footer-wrapper ul {
   list-style: none;
   margin: 3em 0 1em 0;
   color: var(--main-white-color);
}

.footer-wrapper {
   width: 100%;
   background-color: var(--dark-accent-color);
}
.footer {
   height: 100%;
   display: grid;
   grid-template-columns: repeat(12, 1fr);
   /* grid-template-rows: repeat(12, 1fr); */
   max-width: 1160px;
   margin: 0 auto;
   font-size: 0.8em;
   line-height: 1.4;
}
.footer h4 {
   color: var(--main-white-color);
   grid-column: 1/13;
   justify-self: center;
   align-self: center;
   margin: 1em 0;
}
.footer-contact {
   grid-column: 1/4;
}
.footer-contact li:first-child,
.footer-nav li:first-child,
.footer-social li:first-child {
   font-weight: 800;
   text-transform: uppercase;
   margin-bottom: 2em;
}
.footer-contact li:nth-child(2) {
   margin-bottom: 1em;
}
.footer-contact li:nth-child(5) {
   margin-bottom: 1em;
}
.footer-nav {
   grid-column: 4/6;
}
.footer-social {
   grid-column: 6/8;
}
.footer-nav li a,
.footer-social li a {
   width: 90%;
   display: inline-block;
   text-decoration: none;
   color: var(--main-white-color);
   padding: 0.3em 0.3em 0.3em 0;
   /* margin: 0 0 0.5em 0; */
}

.footer-nav li a:hover,
.footer-social li a:hover {
   color: var(--main-black-color);
   background-color: var(--main-white-color);
   box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.75);
}

.footer-nav .donate-button {
   width: auto;
   /* font-weight: bold; */
   display: inline-block;
   text-transform: uppercase;
   letter-spacing: 1px;
   text-decoration: none;
   color: var(--main-white-color);
   font-size: 0.9em;
   padding: 0.9em 4em;
   margin-top: 1.3em;
   transition: all 300ms linear;
   background-color: var(--main-accent-color);
}
.donate-button:hover {
   background-color: var(--main-white-color);
   color: var(--main-accent-color);
}

.footer .newsletter {
   margin: 3em 0 1em 0;
   grid-column: 8/13;
}
.footer .newsletter h3 {
   color: var(--main-white-color);
   font-size: 1em;
   font-weight: 800;
   text-transform: uppercase;
   margin-bottom: 0.2em;
}
.footer .newsletter p {
   color: var(--main-white-color);
   font-size: 1em;
   margin-bottom: 2em;
}
.footer .newsletter input {
   max-width: 200px;
   height: 40px;
   font-size: 1.3em;
}
.footer .newsletter button {
   margin-left: 1em;
   text-transform: uppercase;
   background-color: var(--main-accent-color);
   border: none;
   color: var(--main-white-color);

   font-size: 1.1em;
   height: 40px;
   padding: 0 4em;
   cursor: pointer;
}
.footer .newsletter button:hover {
   background-color: var(--main-white-color);
   color: var(--main-accent-color);
}
.footer .tos {
   grid-column: 1/13;
justify-self: center;
   color: var(--main-white-color);

}
.footer .tos a {
   margin-right: 1em;
   padding: .3em .6em;
text-decoration: none;
   color: rgb(204, 204, 204);
}
.mc__alert{
   color: var(--main-white-color);
}


.footer .tos a:hover {
   color: var(--dark-accent-color);

   background-color: var(--main-white-color);
}
@media only screen and (max-width: 1101px) {
   .footer {
      max-width: 920px;
   }
   .footer .newsletter button {
      margin-left: 0;
      margin-top: 1em;
      padding: 0 4.1em;
   }
}

@media only screen and (max-width: 950px) {
   .footer {
      padding: 0 2.5em;
   }
}

@media only screen and (max-width: 830px) {
   .footer .newsletter {
      grid-column: 9/13;
   }
   .footer-social {
      grid-column: 7/9;
   }
   .footer-nav {
      grid-column: 4/7;
      justify-self: center;
   }
}

@media only screen and (max-width: 600px) {
   .footer-contact {
      grid-column: 2/7;
   }
   .footer-social {
      grid-column: 8/13;
      grid-row: 1;
   }
   .footer-nav {
      grid-column: 2/7;
      justify-self: start;
   }
   .footer .newsletter {
      grid-column: 8/13;
   }
   .footer h4 {
      margin: 1.5em 0;
   }
}

@media only screen and (max-width: 486px) {
   .footer {
      padding: 0 1em;
   }
   .footer .newsletter input {
      max-width: 170px;
   }
   .footer .newsletter button {
      margin-left: 0;
      margin-top: 1em;
      padding: 0 3em;
   }
}

@media only screen and (max-width: 434px) {
   .footer {
      padding: 0 1.5em;
   }
   .footer-contact {
      grid-column: 1/7;
   }
   .footer .newsletter {
      grid-column: 1/12;
      grid-row: 2;
   }
   .footer .newsletter input {
      max-width: 200px;
   }
   .footer .newsletter button {
      margin-top: 1em;
      padding: 0 4em;
   }
   .footer-nav {
      grid-column: 1/7;
      justify-self: start;
   }
}
