.home-about-us {
   width: 100%;
   height: 100%;
   background-color: var(--main-white-color);
}
.home-about-us-grid {
   display: grid;
   grid-template-columns: repeat(12, 1fr);
   grid-template-rows: repeat(12, 60px);
   max-width: 1160px;
   margin: 0 auto;
   padding: 5em 0;
}

.about-us-img {
   width: 100%;
   height: auto;
   /* grid-row: 3/12;    */
   grid-row: 4/12;
   grid-column: 1/7;
   z-index: 20;
}
.about-us-section {
   margin: 3em 0 1em 0;
   grid-row: 1/13;
   background-color: var(--dark-accent-color);
   grid-column: 4/13;
   display: grid;
   grid-template-columns: repeat(12, 1fr);
}
.about-us-section h1 {
   /* padding: 0 1em; */
   grid-column: 6/12;
   font-size: 1.5em;
   margin: 2em 0 0 0;
   color: var(--main-white-color);
}
.about-us-section ol {
   grid-column: 6/12;
   color: var(--main-white-color);
   line-height: 1.4;
}
.about-us-section ol li {
   margin-top: 0.3em;
}
.about-us-section a {
   grid-column: 10/12;
}
.about-us-section button {
   width: 140px;
   height: 40px;
   font-size: 17px;
   font-weight: 700;
   color: var(--main-accent-color);
   background-color: var(--main-white-color);
   border: 1px solid var(--dark-accent-color);
}
.about-us-section button:hover {
   cursor: pointer;
   background-color: var(--dark-accent-color);
   border: 1px solid var(--main-white-color);
   color: var(--main-white-color);
}
@media only screen and (max-width: 1250px) {
   .home-about-us-grid {
      max-width: 1050px;
   }
}

@media only screen and (max-width: 1101px) {
   .home-about-us-grid {
      max-width: 1050px;
   }

   .about-us-section {
      margin: 0;
      grid-row: 1/13;
      background-color: var(--dark-accent-color);
      grid-column: 4/13;
      display: grid;
      grid-template-columns: repeat(12, 1fr);
   }

   .about-us-section ol {
      margin-top: 1em;
   }
   .about-us-section ol li {
      margin-top: 0.2em;
   }
}

@media only screen and (max-width: 950px) {
   .home-about-us-grid {
      grid-template-rows: repeat(12, 60px);
      max-width: 1160px;
      padding: 5em 0;
   }
   .about-us-section h1 {
      grid-column: 3/12;
   }

   .about-us-img {
      grid-row: 4/12;
      grid-column: 1/7;
      margin-left: 0.5em;
   }
}

/* @media only screen and (max-width: 830px) {
   .about-us-section a {
      grid-column: 9/11;
      height: 40px;
      width: 140px;
   }
} */
@media only screen and (max-width: 830px) {
   .home-about-us-grid {
      grid-template-rows: repeat(10, 100px);
      padding-bottom: 0;
   }
   .about-us-img {
      grid-row: 1/4;
      grid-column: 1/13;
      height: 400px;
      margin: 0;
      object-fit: cover;
      overflow: hidden;
   }

   .about-us-section {
      grid-row: 5/13;
      grid-column: 1/13;
   }
   .about-us-section h1 {
      grid-column: 2/12;
   }
   .about-us-section ol {
      grid-column: 3/12;
      grid-row: 2/10;
      margin-top: 2em;
      line-height: 1.4;
   }
   .about-us-section ol li {
      margin-top: 1em;
   }
   .about-us-section a {
      align-self: center;
      justify-self: center;
      grid-row: 10;
      grid-column: 2/12;
      margin: 3em 0;
   }
}
@media only screen and (max-width: 600px) {
   .about-us-section {
      grid-row: 4/13;
      grid-column: 1/13;
   }
   .about-us-img {
      grid-row: 1/4;
      grid-column: 1/13;
      height: 300px;
      margin: 0;
      object-fit: cover;
      overflow: hidden;
   }

   .about-us-section h1 {
      grid-column: 2/12;
   }
   .about-us-section ol {
      grid-column: 3/12;
      grid-row: 2/12;
      margin-top: 2em;
      line-height: 1.4;
   }
   .about-us-section ol li {
      margin-top: 1em;
   }
   .about-us-section a {
      grid-row: 13;
   }
}

@media only screen and (max-width: 350px) {
   .about-us-img {
      grid-row: 1/4;
      grid-column: 1/13;
      height: 300px;
      margin: 0;
   }

   .about-us-section a {
      grid-column: 2/12;
      grid-row: 12;
   }
}
