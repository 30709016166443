.nav-container {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   display: flex;
   align-items: center;
   z-index: 500;
   height: 80px;
   transition: all 200ms ease-in;
}
.nav-container.show-nav {
   background-color: rgba(0, 0, 0, 0.562);
   box-shadow: 3px 21px 30px -21px rgba(0, 0, 0, 0.75);
}
.nav-container .main-navigation {
   display: flex;
   align-items: center;
   justify-content: space-between;
   list-style: none;
   padding: 0;
   width: 1160px;
   margin: 0 auto;
}
.alllinks {
   display: flex;
   position: relative;
}

.alllinks > li a {
   display: inline-block;
   text-transform: uppercase;
   letter-spacing: 1px;
   padding: 0.9em 1.7em;
   text-decoration: none;
   color: var(--main-white-color);
   font-size: 0.9em;
   /* padding: 0.9em 1.4em; */

   transition: all 300ms linear;
}

.logo {
   margin-left: auto;
   padding: 0;
}
.Logos img {
   width: 50px;
   margin-right: 10px;
   margin-bottom: 5px;
}
.logosvg {
   width: 190px;
}


.logo:hover {
   box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
   background-color: transparent;
}

.alllinks > li a:hover {
   color: var(--main-black-color);
}

.alllinks > li a.isActive {
   color: var(--main-black-color);
   background-color: var(--main-white-color);
}

.donate-button {
   background-color: var(--main-accent-color);
}

.alllinks > li .donate-button:hover {
   background-color: var(--main-white-color);
   color: var(--main-accent-color);
}
.alllinks > li a:hover {
   color: var(--main-black-color);
   background-color: var(--main-white-color);
}
.dropdown {
   position: absolute;
   max-width: 350px;
   background-color: var(--main-white-color);
   list-style: none;
   top: 2.7em;
   left: -1px;
   border: 0.5px solid rgb(17, 17, 17);
   border-top: none;
}
.dropdown2 {
   position: absolute;
   max-width: 350px;
   background-color: var(--main-white-color);
   list-style: none;
   top: 2.74em;
   left: 10.67em;
   border: 0.5px solid rgb(17, 17, 17);
   border-top: none;
}
.dropdown3 {
   position: absolute;
   max-width: 350px;
   background-color: var(--main-white-color);
   list-style: none;

   top: 2.74em;
   left: 20.62em;
   border: 0.5px solid rgb(17, 17, 17);
   border-top: none;
}

.dropdown > li a,
.dropdown2 > li a,
.dropdown3 > li a {
   width: 100%;
   display: inline-block;
   text-transform: uppercase;
   letter-spacing: 1px;
   text-decoration: none;
   color: var(--main-black-color);
   font-size: 0.9em;
   padding: 0.9em 1.4em;
   transition: all 300ms linear;
}
.alllinks .dropdown-hover a {
   color: var(--main-black-color);
   background-color: var(--main-white-color);
}

.dropdown > li a:hover,
.dropdown2 > li a:hover,
.dropdown3 > li a:hover {
   color: var(--main-black-color);
   background-color: #a382ff;
}
.menu {
   cursor: pointer;
}
@keyframes animatename {
   0% {
      transform: translateY(-1%);
      opacity: 0;
   }
   100% {
      opacity: 1;
      transform: translateY(0%);
   }
}
@media only screen and (max-width: 1250px) {
   .nav-container .main-navigation {
      width: 1050px;
   }
}

@media only screen and (max-width: 1101px) {
   .nav-container {
      position: fixed;
      top: 0;
      width: 100%;
      display: flex;
      align-items: center;
      height: 70px;
   }

   .nav-container .main-navigation {
      padding: 0 1em;
      width: 950px;
      margin: 0 auto;
   }

   .alllinks {
      display: block;
      opacity: 0;
      background-color: var(--main-black-color);
      width: 350px;
      height: 100vh;
      position: absolute;
      top: 70px;
      right: 0;
      transform: translateX(100%);
      transition: transform 250ms ease-in, opacity 250ms ease-in;
   }

   .alllinks.isShown {
      display: block;
      opacity: 1;
      transform: translateX(0%);
      transition: transform 250ms ease-in;
   }
   .alllinks > li a {
      width: 100%;
      display: inline-block;
      position: relative;
      padding: 0.9em 1.7em;
      text-decoration: none;
      font-size: 0.9em;
   }

   .dropdown {
      animation: animatename 250ms linear;
      position: relative;
      max-width: 350px;
      top: 0;
      left: 0;
      border: none;
   }
   .alllinks .dropdown-hover a {
      background-color: #a382ff;
   }

   .dropdown2 {
      animation: animatename 250ms linear;
      position: relative;
      max-width: 350px;
      background-color: var(--main-white-color);
      list-style: none;
      top: 0;
      left: 0;
      border: none;
      border-top: none;
   }

   .dropdown3 {
      animation: animatename 250ms linear;
      position: relative;
      max-width: 350px;
      border: none;
      top: 0;
      left: 0;
   }
}
@media only screen and (max-width: 1101px) {
   .nav-container .main-navigation {
      padding: 0 1em;
      width: 950px;
   }
}

@media only screen and (max-width: 950px) {
   .nav-container .main-navigation {
      padding: 0 2.5em;
   }
}

@media only screen and (max-width: 950px) {
   .nav-container .main-navigation {
      padding: 0 2em;
   }
}

@media only screen and (max-width: 500px) {
   .Logos img {
      width: 40px;
      margin-right: 10px;
   }
   .Logos .logosvg {
      height: 40px;
      width: auto;
      /* margin-right: 10px; */
   }
   .nav-container .main-navigation {
      padding: 0 1em;
   }
}
