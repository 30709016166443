.donate-container {
   height: 100%;
   max-width: 1160px;
   margin: 0 auto;
   display: grid;
   grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
 grid-column-gap: 3em;
   margin-bottom: 6em;
   justify-items: center;
}
.donate-desc-container {
   max-width: 1160px;
   margin: 0 auto;
}
.donate-desc-container h1{
   font-size: 2.3em;
   margin: 1em 1em .4em 0; 
   color: var(--main-accent-color);
}

.donate-hero {
   height: 35vh;
   width: 100%;
   display: grid;
   grid-template-columns: repeat(12, 1fr);
   grid-template-rows: repeat(2, 1fr);
}
.donate-hero img {
   grid-column: 1/13;
   grid-row: 1/3;
   width: 100%;
   height: 100%;
   object-fit: cover;
   overflow: hidden;
   filter: brightness(80%);
}
.donate-hero h1 {
   z-index: 2;
   color: var(--main-white-color);
   font-size: 3em;
   grid-row: 1/3;
   grid-column: 1/13;
   justify-self: center;
   align-self: center;
   margin-top: 2em;
}

.donate-container .donate-desc-header {
   color: var(--main-black-color);
   font-size: 2.3em;
}

.donate-container h2 {
   font-size: 1.5em;
   margin: 1em 1em 1em 0;
   color: var(--main-accent-color);
}



.donate-container .waystodonate{

   margin-top:3em;

}
.donate-container .donate-now{


   margin-top:3em;
}
.donate-container .why-donate{

   margin-top:3em;
}
.donate-container .donate-now h3,
.donate-container .why-donate h3{
   margin-top:0;
margin-bottom:1.4em;
}
.donate-container .donate-now p{

margin-bottom:1.4em;
}



.donate-container h3 {
   color: var(--main-black-color);
   font-size: 1.2em;
   margin-top: 2em;
   margin-bottom: 1em;
}
.donate-container h4 {
   color: var(--main-black-color);
   font-size: 1.3em;
}
.donate-container .donate-reasons li {
   margin: 0.8em 0 0.8em 0;
   font-size: 1em;
}
.donate-welcome-help {
   margin-top: 2em;
   font-size: 1.3em;
   font-weight: 500;
}


.donate-container ol {
   list-style-position: inside;
   margin-top: 2em;
}
.donate-container ol li {
   margin-top: 1em;
}
.donate-container p {
   font-size: 1.04em;
}
.donate-container a {
   width: 230px;
color: var(--main-accent-color);
   border: solid 1px var(--main-accent-color);
   text-transform: uppercase;
   letter-spacing: 1px;
   text-decoration: none;
   color: var(--main-white-color);
   font-size: 0.9em;
   padding: 1em 4em;

   transition: all 300ms linear;
   background-color: var(--main-accent-color);
   margin: 6em 0 6em 0;
}

.donate-container a:hover {
   background-color: var(--main-white-color);
   color: var(--main-accent-color);
   cursor: pointer;
}
@media only screen and (max-width: 1430px) {
   .donate-container {
      max-width: 1050px;
   }
   .donate-desc-container{
      max-width: 1050px;
   }
}


@media only screen and (max-width: 1240px) {
   .donate-container {
      max-width: 900px;
   }
   .donate-desc-container{
      max-width: 900px;
   }
}
@media only screen and (max-width: 1101px) {
   .donate-container {
      max-width: 850px;

   }
   .donate-desc-container{
      max-width: 850px;
   }
}

@media only screen and (max-width: 1050px) {
   .donate-container {
      max-width: 700px;
      padding: 0 50px;
   }
   .donate-desc-container{
      max-width: 700px;
      padding: 0 50px;
   }
}

@media only screen and (max-width: 600px) {
   .donate-hero h1{
      font-size: 2em;
   }
   
   .donate-container {
      max-width: 570px;
      padding: 0 2em;
   }
   .donate-desc-container h1{
      font-size: 1.6em;
   }
   .donate-container a {
      margin-left: 25%;
   }
   .donate-desc-container{
      max-width: 570px;
      padding: 0 2em;
   }
}
