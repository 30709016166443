.get-involved-wrapper {
   width: 100%;
   background-color: var(--main-accent-color);
}

.get-involved {
   max-width: 1160px;
   margin: 0 auto;
}
.get-involved h1 {
   padding: 3em 0 1em 0;
   text-align: center;
   font-size: 2.2em;
   color: var(--main-white-color);
}

.get-involved h2 {
   text-align: center;
   font-weight: 400;
   font-size: 1.4em;
   color: var(--main-white-color);
}
.get-involved button {
   text-transform: uppercase;
   width: 230px;
   height: 50px;
   font-size: 1.2em;
   color: var(--main-accent-color);
   background-color: var(--main-white-color);
   border: solid 1px var(--main-accent-color);
   transition: all 250ms linear;
}
.get-involved button:hover {
   cursor: pointer;
   background-color: var(--main-accent-color);
   border: solid 1px var(--main-white-color);
   color: var(--main-white-color);
}
.get-involved-button-wrapper {
   padding: 4em 0 6em 0;
   width: 100%;
   text-align: center;
}

@media only screen and (max-width: 1101px) {
   .get-involved {
      max-width: 920px;
   }
   .get-involved h1 {
      font-size: 2em;
   }
   .get-involved h2 {
      font-size: 1.3em;
   }
}
@media only screen and (max-width: 830px) {
   .get-involved {
      padding: 0 2em;
   }
   .get-involved h1 {
      font-size: 2.5em;
   }
}

@media only screen and (max-width: 600px) {
   .get-involved h1 {
      font-size: 2em;
   }
   .get-involved h2 {
      font-size: 1.3em;
   }
}
