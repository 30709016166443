.sponsors {
   width: 100%;
   background-color: white;
   padding: 3em 0;
}
.sponsors h2 {
   font-size: 2.2em;
   text-align: center;
   color: var(--main-accent-color);
   margin-bottom: 2.2em;
}

.sponsors ul {
   list-style: none;
   display: grid;
   grid-gap: 0.5em;
   row-gap: 2em;
   grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
   justify-items: center;
   align-items: center;
   max-width: 1160px;
   margin: 3em auto 5em auto;
   padding: 0 1.5em;
}

.sponsoricon {
   width: 140px;
}
.sponsoricon.smaller {
   width: 120px;
}

.sponsoricon.bigger {
   width: 180px;
}

.sponsors .sponsoricon img {
   width: 100%;
}

@media only screen and (max-width: 1250px) {
   .sponsors ul {
      max-width: 1050px;
   }
}

@media only screen and (max-width: 915px) {
   .sponsors ul {
      max-width: 800px;
      grid-gap: 1em;
      row-gap: 2em;
      grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
   }
   .sponsoricon {
      width: 120px;
   }
   .sponsoricon.smaller {
      width: 110px;
   }

   .sponsoricon.bigger {
      width: 140px;
   }
}
/* @media only screen and (max-width: 600px) {
   .sponsors ul {
      max-width: 400px;
      margin: 0 auto;
      display: block;
   }
   .sponsoricon {
      width: 50%;
      height: 50%;
      display: inline-block;
      margin-bottom: 1em;
   }
   .sponsors .sponsoricon img {
      width: 150px;
      height: 150px;
      display: block;
      margin: 0 auto;
   }
} */

@media only screen and (max-width: 350px) {
   .sponsors ul {
      display: grid;
      max-width: 340px;
      margin: 3em auto;
      row-gap: 1em;
      grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
   }
   .sponsoricon {
      width: 80px;
   }
   .sponsoricon.smaller {
      width: 70px;
   }

   .sponsoricon.bigger {
      width: 100px;
   }
}
