.reports-hero {
   height: 35vh;
   width: 100%;
   display: grid;
   grid-template-columns: repeat(12, 1fr);
   grid-template-rows: repeat(2, 1fr);
}
.reports-hero img {
   grid-column: 1/13;
   grid-row: 1/3;
   width: 100%;
   height: 100%;
   object-fit: cover;
   overflow: hidden;
   filter: brightness(80%);
}
.reports-hero h1 {
   z-index: 2;
   color: var(--main-white-color);
   font-size: 3em;
   grid-row: 1/3;
   grid-column: 1/13;
   justify-self: center;
   align-self: center;
   margin-top: 2em;
}

.reports-container {
   height: 100%;
   display: grid;
   /* grid-template-columns: repeat(12, 1fr); */
   /* grid-template-rows: repeat(12, 1fr); */
   /* grid-template-rows: auto; */
   max-width: 1160px;
   margin: 0 auto;
}
.reports-container h2 {
   font-size: 2.5em;
   padding: 1.5em 0;
}
.reports-container ul {
   list-style: none;
}
.reports-container .newsletter-list {
   margin-bottom: 5em;
}

.reports-container ul li {
   margin: 1em 0;
}
.reports-container ul li a {
   padding: 0.3em 0;
   font-size: 1.1em;
}
@media only screen and (max-width: 1250px) {
   .reports-container {
      max-width: 1050px;
   }
}
@media only screen and (max-width: 1101px) {
   .reports-container {
      max-width: 920px;
   }
}

@media only screen and (max-width: 950px) {
   .reports-container {
      /* max-width: 920px; */
      padding: 0 2.5em;
   }
}

@media only screen and (max-width: 460px) {
   .reports-hero h1 {
      font-size: 3em;
      justify-self: center;
      text-align: center;
      /* align-self: center; */
      /* margin-top: 3em; */
   }
}
