@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;800;900&display=swap");
* {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
}
body {
   margin: 0;
   font-family: "Montserrat", sans-serif;
   font-size: 16px;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}
button {
   font-family: "Montserrat", sans-serif;
}
:root {
   --main-white-color: #fffbf6;
   --main-black-color: #111111;
   --main-accent-color: #5927e5;
   --main-accent-color-hover: #3a0faf;
   --dark-accent-color: #350044;
   --main-grey-color: #4d4d4d;
   --ligh-grey: #e8e8e8;
   --very-light-accent: #f9f5ff;
}
