.video {
    padding: 3em 0;
    width: 100%;
    background-color: #a483ff;
 }
 
 .container3 {
    display: grid;
    justify-items: center;
    /* align-items: center; */
    max-width: 1160px;
    margin: 0 auto 1em auto;
 }
 .video h2 {
    font-size: 2.2em;
    text-align: center;
    color: #fffbf6;
    margin-bottom: 1em;
 } 
 
.videoclass{
   box-shadow: 11px 18px 35px 3px rgba(0,0,0,0.76);
   max-width: 700px;
  
}


@media (max-width: 750px) {
   .container3 {
      max-width: 600px;
   }
   .videoclass{
      max-width: 600px;
   }
}

@media (max-width: 610px) {
   .container3 {
      max-width: 400px;
   }
   .videoclass{
      max-width: 400px;
   }
}
@media only screen and (max-width: 420px) {
   .container3 {
      max-width: 350px;
   }
   .videoclass{
      max-width: 350px;
   }

}
@media only screen and (max-width: 360px) {
   .container3 {
      max-width: 300px;
   }
   .videoclass{
      max-width: 300px;
   }

}
