.blog {
   background-color: var(--main-white-color);
}
.blog-hero {
   height: 35vh;
   width: 100%;
   display: grid;

   grid-template-columns: repeat(12, 1fr);
   grid-template-rows: repeat(2, 1fr);
   /* max-width: 1160px;
   margin: 0 auto;  */
}

.blog-hero img {
   grid-column: 1/13;
   grid-row: 1/3;
   width: 100%;
   height: 100%;
   object-fit: cover;
   overflow: hidden;
   filter: brightness(80%);
}
.blog-hero h1 {
   z-index: 2;
   color: var(--main-white-color);

   font-size: 3em;
   grid-row: 1/3;
   grid-column: 1/13;
   justify-self: center;
   align-self: center;
   margin-top: 2em;
   text-align: center;
}

.blog-grid {
   height: 100%;
   display: grid;
   grid-template-columns: repeat(12, 1fr);
   max-width: 1160px;
   margin: 0em auto;
   padding: 4em 0 10em 0;
}
.blog-grid h2 {
   color: var(--main-accent-color);
   grid-column: 1/8;
   font-size: 2em;
   margin: 0 0 1em 0;
   line-height: 1.5;
}
.blog-grid .second-heading {
   margin: 1em 0 1em 0;
   line-height: 1.5;
}
.blog-grid h3 {
   font-size: 1.2em;
   font-weight: 500;
   grid-column: 1/8;
   margin: 0 0 3em 0;
   line-height: 1.5;
}

.blog-grid p {
   grid-column: 1/7;
   line-height: 1.6;
   font-size: 1.1em;
}
.blog-grid p p {
   font-size: 1em;
   margin-top: 1em;
}

.blog-grid img {
   grid-column: 8/13;
   width: 100%;
}
.list-title {
   font-weight: 800;
   margin: 0 0 1em 0;
}
.blog-grid p ul {
   list-style: none;
}

.blog-grid p ol {
   margin-top: 1em;
}
.blog-grid p ol li {
   margin-left: 1.2em;
}
.blog-grid p ul li {
   margin-bottom: 1em;
}

.blog-grid .safeschool {
   margin-top: 1em;
   list-style-type:disc;
   list-style-position: outside;
   margin-left: 1em;
}
.blog-grid a {
   margin-top: 1em;
   grid-column: 1/5;
   line-height: 1.6;
}

.blog-grid p .sponsors-list {
   margin-top: 2.5em;
}
.blog-grid p .sponsors-list li a {
   padding: 0.4em 0;
}

@media only screen and (max-width: 1250px) {
   .blog-grid {
      max-width: 1050px;
   }
}
@media only screen and (max-width: 1101px) {
   .blog-grid {
      max-width: 920px;
   }
}
@media only screen and (max-width: 950px) {
   .blog-grid h2 {
      grid-column: 1/11;
      margin: 1em 0 1em 0;
   }
   .blog-grid {
      max-width: 920px;

      padding: 4em 2.5em 10em 2.5em;
   }
}

@media only screen and (max-width: 600px) {
   .blog-grid h2 {
      grid-column: 1/13;
      font-size: 2.2em;
   }
   .blog-grid h3 {
      grid-column: 1/13;
      font-size: 1.3em;
   }

   .blog-grid img {
      grid-column: 1/13;
      grid-row: 3/3;
      margin-bottom: 3em;
   }
   .blog-grid p {
      grid-column: 1/13;
      line-height: 1.6;
      font-size: 1.2em;
   }
}

@media only screen and (max-width: 460px) {
   .blog-hero h1 {
      font-size: 2.4em;
      justify-self: center;
      text-align: center;
      /* align-self: center; */
      /* margin-top: 3em; */
   }
}
