.success-stories-wrapper {
   width: 100%;
   background-color: var(--main-black-color);
}
.success-stories {
   height: 100%;
   display: grid;
   grid-template-columns: repeat(12, 1fr);

   max-width: 1160px;
   margin: 0 auto;
   padding-bottom: 2em;
}
.success-stories h1 {
   grid-column: 1/13;

   font-size: 2.2em;
   text-align: center;
   color: var(--main-white-color);
   padding: 2em 0 2em 0;
}

.success-card {
   grid-column: 2/9;
   background-color: var(--main-white-color);
   padding: 2.5em;
   margin-bottom: 6em;
}

.success-card.right-aligned {
   grid-column: 5/12;
}
.success-card.right-aligned h2 {
   font-size: 2em;
}

.success-card h2 {
   font-size: 2em;
   width: 100%;
   margin-bottom: 1em;
   color: var(--main-accent-color);
}
.success-card p {
   width: 100%;
   line-height: 1.6;
}

.success-stories-button-wrapper {
   padding: 0em 0 6em 0;
   width: 100%;
   text-align: center;
}
.success-stories-wrapper button {
   text-transform: uppercase;
   width: 230px;
   height: 50px;
   font-size: 1.2em;
   color: var(--main-accent-color);
   background-color: var(--main-white-color);
   border: solid 1px var(--main-accent-color);
   transition: all 250ms linear;
}
.success-stories-wrapper button:hover {
   cursor: pointer;
   /* background-color: var(--main-accent-color); */
   background-color: var(--main-black-color);
   border: solid 1px var(--main-white-color);
   color: var(--main-white-color);
}
@media only screen and (max-width: 830px) {
   .success-card {
      grid-column: 3/11;
      padding: 2.5em;
      margin-bottom: 6em;
   }
   .success-card h2 {
      width: 100%;
   }
   .success-card p {
      width: 100%;
   }

   .success-card.right-aligned {
      grid-column: 3/11;
   }
   .success-card.right-aligned h2,
   .success-card.right-aligned p {
      margin: 0 0 1em 0;
      width: 100%;
   }
}
@media only screen and (max-width: 460px) {
   .success-card {
      grid-column: 2/12;
   }
   .success-card.right-aligned {
      grid-column: 2/12;
   }
}
